import { Box, FormControl, FormGroup, Typography } from '@mui/material'
import { scrollStyle } from '../../../../../utils/constants'
import { filterBoxStyle } from '../../utils'
import MnfrItem from './MnfrItem'

export default function Mnfr({ mnfrs, filters, setFilters, setPage, setSf }) {
    const handleCheck = (e) => {
        const newValue = parseInt(e.target.value)
        filters?.mnfr?.includes(newValue)
            ?   setFilters(prev => ({ ...prev, mnfr: prev.mnfr.filter(m => m !== newValue) }))
            :   setFilters(prev => ({ ...prev, mnfr: [...prev.mnfr, newValue] }))
        setSf(null)
        setPage(1)
    }

    return (
        <Box sx={{ ...filterBoxStyle } } >
            <Typography
                sx={{
                    textAlign: 'center', fontSize: '14px',
                    borderBottom: '1.5px solid', fontWeight: 'bold',
                    borderBottomColor: 'secondary.light', mb: 1, p: '5px', position: 'relative',
                    color: 'secondary.light'
                }}
            >
                Үйлдвэрлэгч ({mnfrs?.length})
            </Typography>
            <Box
                sx={{
                    ...scrollStyle, '&::-webkit-scrollbar': { width: 6 },
                    maxHeight: '300px', overflow: 'hidden', overflowY: 'auto'
                }}
            >
                <FormControl>
                    <FormGroup>
                        {
                            mnfrs.map(
                                m => 
                                    <MnfrItem
                                        key={m.id}
                                        mnfr={m}
                                        filters={filters}
                                        handleCheck={handleCheck}
                                    />
                                )
                        }
                    </FormGroup>
                </FormControl>
            </Box>
        </Box>
    )
}
