import { Box, FormControl, FormGroup, Typography } from '@mui/material'
import { scrollStyle } from '../../../../../utils/constants'
import { filterBoxStyle } from '../../utils'
import VndrItem from './VndrItem'

export default function Vndr({ vndrs, filters, setFilters, setPage, setSf }) {
    const handleCheck = (e) => {
        const newValue = parseInt(e.target.value)
        filters?.vndr?.includes(newValue)
            ?   setFilters(prev => ({ ...prev, vndr: prev.vndr.filter(m => m !== newValue) }))
            :   setFilters(prev => ({ ...prev, vndr: [...prev.vndr, newValue] }))
        setSf(null)
        setPage(1)
    }

    return (
        <Box sx={{ ...filterBoxStyle } } >
            <Typography
                sx={{
                    borderBottom: '1.5px solid',
                    textAlign: 'center', fontSize: '14px', fontWeight: 'bold',
                    borderBottomColor: 'secondary.light', mb: 1, p: '5px', position: 'relative',
                    color: 'secondary.light'
                }}
            >
                Нийлүүлэгч ({vndrs?.length})
            </Typography>
            <Box
                sx={{
                    ...scrollStyle, 
                    '&::-webkit-scrollbar': { width: 6 },
                    maxHeight: '300px', 
                    overflow: 'hidden', 
                    overflowY: 'auto'
                }}
            >
                <FormControl>
                    <FormGroup>
                        {
                            vndrs.map(v => 
                                    <VndrItem
                                        key={v.id}
                                        vendor={v}
                                        filters={filters}
                                        handleCheck={handleCheck}
                                    />
                                )
                        }
                    </FormGroup>
                </FormControl>
            </Box>
        </Box>
    )
}
