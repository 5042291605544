import { Checkbox, FormControlLabel, Typography } from "@mui/material"

export default function VndrItem({ vendor, filters, handleCheck }) {
    const isSelected = filters?.vndr?.includes(vendor?.id)
    return (
        <FormControlLabel
            sx={{ marginBottom: '5px' }}
            value={vendor?.id}
            control={
                <Checkbox
                    checked={isSelected}
                    onChange={handleCheck}
                    size='small'
                    sx={{ 
                        p: 0, 
                        pl: 1, 
                        m: 'auto 0',
                        color: 'primary.light',
                        '&.Mui-checked': {
                            color: 'secondary.light',
                        },
                    }}
                />
            }
            label={
                <Typography 
                    sx={{ 
                        color: isSelected ? 'secondary.light' : 'primary.light',
                        '&:hover': { color: 'secondary.light' },
                        fontSize: '13.5px',
                        ml: 1,
                    }}
                >{vendor?.name}</Typography>
            }
        />
    )
}
