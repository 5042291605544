import { Button, TableBody, TableCell, Typography } from '@mui/material'
import { isTokenValid } from '../../../../../../utils/functions'
import ProductRow from './ProductRow'
import { isEmpty } from 'underscore'

export default function ProductTableBody ({ setOpen, products, colFields, filters, toDelIds, setToDelIds, setSelectedProduct, count, page, pageSize, isFetching, handleAddOpen }) {
    const { macs } = isTokenValid()

    return (
        <TableBody sx={{ border: 'none' }}>
            {
                (products?.length && !isFetching)
                    ?   products.map(
                            (p, idx) => (
                                <ProductRow 
                                    key={p.id} 
                                    product={p} 
                                    colFields={colFields} 
                                    idx={idx} 
                                    toDelIds={toDelIds} 
                                    setToDelIds={setToDelIds} 
                                    setSelectedProduct={setSelectedProduct} 
                                    setOpen={setOpen}
                                />
                                
                            ))
                    :   <TableCell colSpan={colFields?.length + 2} sx={{ p: 2, textAlign: 'center' }}>                            
                            {
                                isEmpty(filters)
                                    ?   macs
                                            ?   <Typography>MACS системийг хэрэглэгч байгууллагын хувьд MACS-с барааны мэдээллээ оруулна.</Typography>
                                            :   <Button size='small' variant='contained' onClick={handleAddOpen} >Бараа нэмэх</Button>
                                    :   <Typography sx={{ color: 'primary.main', mb: 2, mt: 3 }}>Тохирох барааны мэдээлэл олдсонгүй!</Typography>
                            }
                        </TableCell>
                }
        </TableBody>
    )
}