import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material"
import { getDirections, updateDirectionOfCustomer } from "../../../../../api/customer"
import { useCallback, useEffect, useRef, useState } from "react"
import { getToast } from '../../../../auth/helpers/ToastAlert'
import { scrollStyle } from "../../../../../utils/constants"
import { getCustomers } from "../../../../../api/company"
import TablePages from "../../../../common/TablePages"
import SelectDirection from "../SelectDirection"
import { isEmpty, keys } from "underscore"
import HeadCell from "./Cells/HeadCell"
import { useQuery } from "react-query"
import FilterRow from "./Filter"
import Row from "./Row"

export default function PharmoCustomers({ setCustomer, isLocationUpdated }) {
	const [columns, setColumns] = useState({
		name: { label: "Нэр", order: "", align: "center" },
		rd: { label: "РД", order: "", align: "center" },
		is_company: { label: "Компани эсэх", order: "", align: "center" },
		debt: { label: "Нийт өр", order: "", align: "center" },
		credit: { label: "Зээлийн лимит", order: "", align: "center" },
		badCnt: { label: "Найдваргүйн тоо", order: "", align: "center" },
		location: { label: "Байршил?", order: "", align: "center" },
		direction: { label: "Чиглэл", order: "", align: "center" },
		isBad: { label: "Найдваргүй", order: "", align: "center" },
		email: { label: "И-мэйл", order: "", align: "center" },
		phone: { label: "Утасны дугаар", order: "", align: "center" },
		orders_cnt: { label: "Захиалга тоо", order: "", align: "center" },
		orders_sum: { label: "Захиалга дүн", order: "", align: "center" },
		created: { label: "Огноо", order: "", align: "center" },
	})
	// ordering
	const handleOrder = (fieldName) => {
		const c = columns?.[fieldName]
		const v =
			c?.order === ""
				? fieldName
				: c?.order === fieldName
					? `-${fieldName}`
					: ""
		setColumns({ ...columns, [fieldName]: { ...c, order: v } });
	}

	const orderParam = keys(columns)?.reduce((acc, k) => {
		const cur = columns?.[k]?.order;
		if (cur !== "") {
			acc += '&ordering=' + cur
		}
		return acc
	}, "")

	const [page, setPage] = useState(1)
	const [pageSize, setPageSize] = useState(20)
	const [filtering, setFiltering] = useState(null)

	const [partners, setPartners] = useState([])
	const [count, setCount] = useState(0)
	const getData = (res) => { setPartners(res?.data?.results || []); setCount(res?.data?.count || 0) }
	const { data, refetch: refetchAllCusts } = useQuery(["get_customers", page, pageSize, orderParam, filtering], getCustomers, { onSuccess: getData })
	useEffect(() => { if (isLocationUpdated) { refetchAllCusts() } }, [isLocationUpdated])
	//console.log("D: ", data)
	useEffect(() => {
		if (!isEmpty(data?.data)) { setPartners(data?.data?.results || []) }
		else { setPartners([]) }
	}, [data?.data])

	const selectCustomer = (customer) => { setCustomer({...customer, is_pharmo_user: true }) }
	const hhRef = useRef(null)

	// toast msg
	const [msg, setMsg] = useState(null)
	const msgOff = () => { setMsg(null) }
	const waitMsg = () => {setMsg({ m: 'Түр хүлээгээд дахин оролдоно уу!', s: 'warning' })}

	// select direction
	const [directions, setDirections] = useState([])
	const fetchDirections = () => {
		getDirections()
			.then(res => {
				setDirections([...res?.data, {id: 0, name: 'Болих'}] || [])
			})
			.catch(e => {
				console.log("ERR: ", e)
				waitMsg()
			})
	}
	useEffect(() => { fetchDirections() }, [])

	const [selectedCustId, setSelectedCustId] = useState(null)
	const handleSelectCustId = (e, custId) => {
		setSelectedCustId(custId)
		e?.stopPropagation()
	}
	const [directionBoxOpen, setDirectionBoxOpen] = useState(false)
	const directionBoxOff = () => { setDirectionBoxOpen(false); setSelectedCustId(null) }
	useEffect(() => {
		if (selectedCustId) {
			if (directions.length <= 0) {
				waitMsg()
			} else {
				setDirectionBoxOpen(true)
			}
		}
	}, [selectedCustId])
	const handleSelectDirection = useCallback((directionId) => {
		let data = { customer_id: selectedCustId, of_supplier: false }
		if (directionId) {
			data = { ...data, direction_id: directionId }
		} else if (directionId === 0) {
			data = { ...data, direction_id: null }
		}
		updateDirectionOfCustomer(data)
			.then(res => {
				if (res?.data) {
					refetchAllCusts()
					setMsg({ m: 'Амжилттай!', s: 'success' })
				}
			})
			.catch(e => {
				waitMsg()
			})
			.finally(() => { directionBoxOff() })
	}, [selectedCustId])

	return (
		<>
			<TableContainer sx={{ mt: 1, height: '100%', ...scrollStyle, '&::-webkit-scrollbar': { width: 8, height: 8 } }}>
				{getToast(Boolean(msg?.m), msgOff, msg?.m, msg?.s, 1500)}
				<Table stickyHeader aria-label="simple table">
					<TableHead>
						<TableRow
							sx={{
								"& > th": {
									fontSize: "12px",
									backgroundColor: "secondary.light",
									color: "white",
									borderRight: "1px solid white",
									p: "4px 4px",
									textAlign: "center",
								},
							}}
							ref={hhRef}
						>
							<TableCell>
								<Typography sx={{ fontWeight: "bold", fontSize: "12px" }}>№</Typography>
							</TableCell>
							{
								keys(columns)?.map((c) => (
									<HeadCell
										key={c}
										field={columns?.[c]}
										fieldName={c}
										handleOrder={handleOrder}
									/>
								))
							}
						</TableRow>
					</TableHead>
					<FilterRow filtering={filtering} setFiltering={setFiltering} />
					<TableBody>
						{
							partners?.map((row, idx) => (
								<Row
									key={`${idx}`}
									row={row}
									setPartners={setPartners}
									idx={idx}
									page={page}
									pageSize={pageSize}
									selectCustomer={selectCustomer}
									directions={directions}
									handleSelectCustId={handleSelectCustId}
								/>
							))
						}
					</TableBody>
					<TablePages
						count={count}
						page={page}
						setPage={setPage}
						pageSize={pageSize}
						setPageSize={setPageSize}
						colSpan={keys(columns)?.length + 1}
					/>
				</Table>
			</TableContainer>
			{
				directions.length > 0 &&
				<SelectDirection
					directionBoxOpen={directionBoxOpen}
					directionBoxOff={directionBoxOff}
					directions={directions}
					handleSelectDirection={handleSelectDirection}
				/>
			}
		</>
	)
}