import AxiosWithToken from "../global/axiosBase"

export const getCustomerBranchesLocation = (customerId) => {
    return AxiosWithToken.get(`customer_location/?customer_id=${customerId}`)
}

export const updateCustomerLocation = (data) => {
    return AxiosWithToken.patch('customer_location/', data)
}

// customer direction
export const addUpdateDirection = (data) => {
    return AxiosWithToken.post('customer_direction/', data)
}

export const removeDirection = (directionId) => {
    return AxiosWithToken.delete('customer_direction/?direction_id=' + directionId)
}

export const getDirections = () => {
    return AxiosWithToken.get('customer_direction/')
}

export const updateDirectionOfCustomer = (data) => {
    return AxiosWithToken.patch('customer_direction/', data)
}