import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material"
import { Box, Checkbox, TableCell, TableRow } from "@mui/material"
import { toPrice } from "../../../../../../../utils/functions"
import { getImageUrl } from "../../../../../../utils"
import { useProductListCtx } from "../.."

export default function ProductRow({ product, colFields, toDelIds, setToDelIds, setSelectedProduct, setOpen, idx, count, page, pageSize }) {
    const imgUrl = product?.images?.[0]?.url
    const imageUrl = imgUrl ? getImageUrl(imgUrl, 150) : null
    //console.log("product: ", imageUrl)
    const { cats } = useProductListCtx()
    const pf = ['price', 'sale_price']
    const handleChecked = (e, pId) => {
        e.stopPropagation()
        toDelIds.includes(pId)
            ?   setToDelIds(prev => ([...prev.filter(id => id !== pId)]))
            :   setToDelIds(prev => ([...prev, pId]))
    }
    const isIncluded = (pId) => toDelIds.includes(pId)

    const handleEditOpen = (p) => {
        setSelectedProduct(p)
        setOpen(true)
    }

    const handleImageOpen = (e, url) => {
        e?.stopPropagation()
        if (url) {
            const lnk = url.replace('_150x150', '_1000x1000') 
            window.open(lnk, '_blank', 'noopener,noreferrer')
        }
    }

    return (
        <TableRow
            sx={{
                '&:hover': { 
                    backgroundColor: '#f2f2f2',
                    cursor: 'pointer'
                },
                maxHeight: '50px',
                minHeight: '50px', 
                overflow: 'hidden',
                '& td': { 
                    p: '3px', 
                    fontSize: '14px', 
                    maxHeight: '40px', 
                    height: '40px' 
                }
            }}
            onClick={() => { handleEditOpen(product) }}
        >
            <TableCell sx={{ padding: 0 }}>
                <Checkbox
                    checked={ isIncluded(product.id) }
                    sx={{ 
                        p: 0, 
                        display: 'flex', 
                        justifyContent: 'center' 
                    }}
                    checkedIcon={
                        <CheckBox 
                            sx={{ 
                                '&.MuiSvgIcon-root': { 
                                        fontSize: '15px' 
                                    }
                                }}
                            />
                            }
                    icon={
                        <CheckBoxOutlineBlank 
                            sx={{
                                '&.MuiSvgIcon-root': { fontSize: '15px' }
                            }}
                        />
                    }
                    onClick={e => { handleChecked(e, product.id) }}
                />
            </TableCell>
            <TableCell>{ (count > pageSize && page !== 1) ? idx + 1 + page * pageSize : idx + 1 }</TableCell>
            <TableCell
                sx={{
                    b: '1px solid #f1f1f1',
                }}
                onClick={(e) => {handleImageOpen(e, imageUrl)}}
            >
                {
                    imageUrl
                        ?   <img 
                                src={imageUrl} 
                                alt={product.name} 
                                style={{ 
                                    width: '40px', 
                                    height: '40px', 
                                    objectFit: 'contain'
                                }} 
                            />
                        :   <Box sx={{ width: '40px', height: '40px', backgroundColor: 'white', border: '1px solid grey', borderColor: '#f1f1f1' }} />
                }
            </TableCell>
            {
                colFields?.map(field => 
                    (
                        <TableCell key={field} sx={{ textAlign: field !== 'name' ? 'right' : 'left' }}>
                            <div style={{ maxHeight: field != 'name' ? '60px' : 'fit-content', overflow: 'hidden' }}>
                                { 
                                    field === 'category'
                                        ?   cats?.filter(c => product[field]?.includes(c.pk)).map(c => c?.name).join(', ')
                                        :   pf.includes(field)
                                                ?   toPrice(product[field])
                                                :   ['vndr', 'mnfr'].includes(field)
                                                        ?   product[field]?.name
                                                        :   product[field]
                                }
                            </div>
                        </TableCell>
                    )
                )
            }
        </TableRow>
    )
}
