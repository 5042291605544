import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material"
import { getSalesByCustomer } from "../../../../../../../api/report"
import { getToast } from "../../../../../../auth/helpers/ToastAlert"
import { scrollStyle } from "../../../../../../../utils/constants"
import { toPrice } from "../../../../../../../utils/functions"
import ExportAsExcel from "../../Export/ExportAsExcel"
import SalesTableChart from "../SalesTableChart"
import { useEffect, useState } from "react"
import { useQuery } from "react-query"

export default function ByCustomer({ ym1, ym2, monthOrQuarter }) {
    const filters = { ...ym1 }//, ...ym2 }
    const { data, error } = useQuery(["getSalesByCustomer", filters, monthOrQuarter], getSalesByCustomer, { enabled: true })
    const [result, setResult] = useState([])

    const [order, setOrder] = useState('asc')
    const [orderBy, setOrderBy] = useState('date')
    const handleOrder = (col) => {
        if (orderBy === col) {
            setOrder(order === 'asc' ? 'desc' : 'asc')
        } else {
            setOrder('asc')
            setOrderBy(col)
        }
    }
    useEffect(() => {
        if (data?.data) {
            const sorted = [...data.data].sort((a, b) => {
                if (a[orderBy] > b[orderBy]) return order === 'asc' ? 1 : -1
                if (a[orderBy] < b[orderBy]) return order === 'asc' ? -1 : 1
                return 0
            })
            setResult(sorted)
        }
    }, [data?.data, order, orderBy])
    
    // export excel
    const headerCell1 = 'Харилцагч'
    const mergedCells = 'A1:C2'
    const mergeCellRowCount = 2
    const mergedCellValue = 'Нийт борлуулалт (харилцагчаар)'

    return (
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
            {getToast(Boolean(error), () => {}, ' Түр хүлээгээд дахин оролдоно уу!', 'warning', 1500)}
            <Paper sx={{ p: 1, boxShadow: 5 }}>
                <ExportAsExcel
                    tableData={result} 
                    tableHeader={[headerCell1, 'Тоо', 'Дүн']} 
                    mergedCells={mergedCells}
                    mergeCellRowCount={mergeCellRowCount}
                    mergedCellValue={mergedCellValue} 
                    labelName={'customer_name'}
                    fileName="pharmo_sales_by_customer"
                    sheetName={`${ym1.year} оны ${ym1.month}-р сар`}
                />
                <TableContainer sx={{ ...scrollStyle, '&::-webkit-scrollbar': { width: 8 }, width: '500px', maxHeight: '500px' }}>
                    <Table>
                        <TableHead sx={{ '&>th': { backgroundColor: 'secondary.light', color: 'white', borderRight: '1px solid white', p: '2px 6px', textAlign: 'center', cursor: 'pointer' } }}>
                            <TableCell>№</TableCell>
                            <TableCell onClick={() => handleOrder('customer_name')}>Харилцагч</TableCell>
                            <TableCell onClick={() => handleOrder('count')}>Тоо</TableCell>
                            <TableCell onClick={() => handleOrder('total')}>Дүн</TableCell>
                        </TableHead>
                        <TableBody>
                            {
                                result?.length > 0
                                    ?   
                                        result.map((item, index) => (
                                            <TableRow key={index} sx={{ '&>td': { p: '2px 6px' } }}>
                                                <TableCell width='30px' align='center'>{index + 1}</TableCell>
                                                <TableCell align='left' style={{ paddingLeft: '20px' }}>{item.customer_name}</TableCell>
                                                <TableCell align='center'>{item.count}</TableCell>
                                                <TableCell align='right' style={{ paddingRight: '40px' }}>{item.total ? toPrice(item.total) : '-'}</TableCell></TableRow>
                                        ))
                                    :   <TableRow>
                                            <TableCell colSpan={4}>
                                                <Typography align='center' variant='body2'>Мэдээлэл байхгүй байна.</Typography>
                                            </TableCell>
                                        </TableRow>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
            <SalesTableChart
                tableData={result} 
                titleText={mergedCellValue}
                labels={result.map(item => item.customer_name)}
                monthOrQuarter={monthOrQuarter} 
            />
        </Box>
    )
}
