import { scrollStyle } from "../../../../../utils/constants"
import { Box, Dialog, Typography } from "@mui/material"

export default function SelectDirection({ directionBoxOpen, directionBoxOff, directions, handleSelectDirection }) {
    return (
        <Dialog 
            open={directionBoxOpen}
            onClose={directionBoxOff}
        >
            <Box 
                sx={{ 
                    display: 'flex', 
                    flexDirection: 'column', 
                    p: 2
                }}
            >
                <Typography
                    sx={{
                        p: '6px',
                        pt: 1,
                        pb: 0,
                        color: 'secondary.light',
                        borderBottom: '1px solid',
                        borderBottomColor: 'secondary.light',
                    }}
                >Чиглэлүүд:</Typography>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column', 
                        p: 2,
                        pt: 0,
                        maxHeight: '350px',
                        overflowY: 'auto',
                        cursor: 'pointer',
                        ...scrollStyle,
                        '&::-webkit-scrollbar-thumb': {
                            backgroundColor: 'secondary.light',
                        },
                        boxShadow: '0 0 8px 0 rgba(0,0,0,0.1)',
                    }}
                >
                    {directions.map((d, i) => (
                        <Box
                            key={d?.id}
                            sx={{ 
                                display: 'flex', 
                                flexDirection: 'row', 
                                gap: 1, 
                                mt: 1,
                                borderRadius: 1,
                                '&:hover': {
                                    backgroundColor: '#f0f0f0',
                                },
                                transition: '0.15s',
                            }}
                            onClick={() => { handleSelectDirection(d.id) }}
                        >
                            {
                                d?.id !== 0 && 
                                <Typography
                                    sx={{ p: '2px 6px', mb: '2px' }}
                                >{`${i+1}.`}</Typography>
                            }
                            <Typography
                                sx={{ p: '2px 6px', mb: '2px', color: d?.id === 0 ? 'orange' : 'initial' }}
                                flexGrow={1}
                            >{d?.name}</Typography>
                        </Box>
                    ))}
                </Box>
            </Box>
        </Dialog>
    )
}
