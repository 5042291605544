import SimpleSearch from "../ResultSection/ByProduct/SimpleSearch"
import MonthOrQuarter from "./MonthOrQuarter"
import DateSelector from "./DateSelect"
import ReportType from "./ReportType"
import { Box } from "@mui/material"

export default function FilterSection({ reportType, setReportType, ym1, setYm1, ym2, setYm2, currentYear, monthOrQuarter, setMonthOrQuarter, setProduct }) {
    return (
        <Box sx={{ p: 2, display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 2 }}>
            <ReportType reportType={reportType} setReportType={setReportType} />
            {
                reportType === 'by_product' &&
                    <SimpleSearch setSelectedProduct={setProduct} />
            }
            <DateSelector
                ym1={ym1}
                setYm1={setYm1}
                ym2={ym2}
                setYm2={setYm2}
                currentYear={currentYear}
                reportType={reportType}
            />
            { reportType === 'total_sales' &&
                <MonthOrQuarter monthOrQuarter={monthOrQuarter} setMonthOrQuarter={setMonthOrQuarter} />
            }
        </Box>
    )
}
