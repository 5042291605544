import { Checkbox, TableCell, TableHead, TableRow } from "@mui/material"
import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material"
import ProductFilterRow from "./ProductFilterRow"
import { useRef } from "react"

export default function ProductTableHeader({ colNames, selCnt, prodsCnt, handleSelectAll }) {
    const hhRef = useRef(null)
    return (
        <TableHead>
            <TableRow
                sx={{
                    '& th': {
                        backgroundColor: 'secondary.light',
                        color: 'white', borderRight: '1px solid white',
                        p: '3px', fontSize: '13px', textAlign: 'center'
                    }
                }}
                ref={hhRef}
            >
                <TableCell padding='checkbox' width='25px'>
                    <Checkbox
                        indeterminate={ selCnt > 0 && selCnt < prodsCnt }
                        checked={ selCnt > 0 && selCnt === prodsCnt }
                        checkedIcon={ <CheckBox sx={{ '&.MuiSvgIcon-root': { fontSize: '15px' } }} /> }
                        icon={ <CheckBoxOutlineBlank sx={{ '&.MuiSvgIcon-root': { fontSize: '15px' } }} /> }
                        onChange={ handleSelectAll }
                        sx={{ color: 'white' }}
                    />
                </TableCell>
                <TableCell width='15px'>№</TableCell>
                <TableCell width='15px'>Зураг</TableCell>
                {colNames?.map(colName => (<TableCell key={colName}>{colName}</TableCell>))}
            </TableRow>
            <ProductFilterRow hr={hhRef} />
        </TableHead>
    )
}