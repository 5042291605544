import { getCustomerBranchesLocation, updateCustomerLocation } from '../../../../api/customer'
import { Box, Button, Dialog, Tab, Tabs, Typography } from '@mui/material'
import { getToast } from '../../../auth/helpers/ToastAlert'
import SupplierCustomers from './SupplierCustomers'
import CustomerDirection from './CustomerDirection'
import InvitedCustomers from './InvitedCustomers'
import CustomerLocation from './CustomerLocation'
import PharmoCustomers from './PharmoCustomers'
import { useEffect, useState } from 'react'
import SelectBranch from './SelectBranch'
import TestTable from './TestTable'

export default function Customer() {
    const [tab, setTab] = useState(0)

    const [customer, setCustomer] = useState(null)
    const [branches, setBranches] = useState([])
    const [selectedBranch, setSelectedBranch] = useState(null)

    const [addressTxt, setAddressTxt] = useState(null)
    const handleSelectBranch = (branchId) => {
        const branch = branches.find(b => b.id === branchId)
        setSelectedBranch(branch)
        setAddressTxt(branch?.address)
        console.log("Baddr: ", branch?.address)
    }

    const [open, setOpen] = useState(false)
    const handleDialogClose = () => { setOpen(false) }
    useEffect(() => {
        if (!open) {
            setCustomer(null)
            setSelectedBranch(null)
            setBranches([])
        }
    }, [open])

    const [msg, setMsg] = useState(null)
    const msgOff = () => { 
        setMsg(null)
        handleDialogClose()
    }

    const [newLocation, setNewLocation] = useState(null)
    const [isLocationUpdated, setIsLocationUpdated] = useState(false)
    const updateLocation = () => {
        const data = { customer_id: customer.id, branch_id: selectedBranch?.id, ...newLocation }
        updateCustomerLocation(data)
            .then(res => {
                setMsg({ m: 'Амжилттай хадгалагдлаа!', s: 'success' })
                setIsLocationUpdated(true)
            })
            .catch(e => {
                console.log("ERR: ", e)
                setMsg({ m: 'Түр хүлээгээд дахин оролдоно уу!', s: 'warning' })
            })
            .finally(() => {})
    }

    //console.log("C: ", customer)

    useEffect(() => {
        if (customer?.is_pharmo_user) {
            getCustomerBranchesLocation(customer?.id)
                .then(res => {
                    const branches = res?.data
                    if (branches?.length) {
                        setBranches(branches)
                        setAddressTxt(branches?.[0]?.address)
                        setSelectedBranch(branches?.[0])
                        setOpen(true)
                    }
                })
                .catch(e => {
                    setMsg({ m: 'Түр хүлээгээд дахин оролдоно уу!', s: 'warning' })
                    console.log("ERR: ", e?.response?.data)
                    if (e?.response?.data?.customer_id) { setMsg({ m: 'Харилцагчийн мэдээлэл олдсонгүй!', s: 'warning' }) }
                })
                .finally(() => { setIsLocationUpdated(false) })
        } else if (customer) {
            setOpen(true)
            setAddressTxt(customer?.note)
            setIsLocationUpdated(false)
        }
    }, [customer])

    return (
        <>
            <Tabs
                value={tab}
                onChange={(e, v) => setTab(v)}
                aria-label='tabs'
                sx={{
                    minHeight: '30px', height: '30px',
                    '& .MuiTab-root': { minHeight: '30px', height: '30px' }
                }}
            >
                <Tab label={'PHARMO харилцагч'} value={0} />
                <Tab label={'MACS харилцагч'} value={1} />
                <Tab label={'Урьсан хэрэглэгч'} value={2} />
                {/* <Tab label={'Харилцагчийн чиглэл'} value={3} /> */}
                {/* <Tab label={'Харилцагчийн чиглэл'} value={4} /> */}
            </Tabs>
            <Box sx={{ height: '100%' }}>
                {tab === 0 && <PharmoCustomers isLocationUpdated={isLocationUpdated} setCustomer={setCustomer} /> }
                {tab === 1 && <SupplierCustomers isLocationUpdated={isLocationUpdated} setCustomer={setCustomer} />}
                {tab === 2 && <InvitedCustomers />}
                {/* {tab === 3 && <CustomerDirection />} */}
                {/* {tab === 4 && <TestTable />} */}
            </Box>
            <Dialog open={open} onClose={handleDialogClose} fullWidth maxWidth='lg'>
                <Box textAlign={'center'} mt={2}>
                    <Typography>
                        Харилцагч: <span style={{ fontStyle: 'italic', fontWeight: 'bold' }}>{customer?.name}</span>
                    </Typography>
                    {
                        branches?.length > 1 &&
                        <SelectBranch 
                            selectedBranch={selectedBranch} 
                            handleSelectBranch={handleSelectBranch}
                            branches={branches}
                        />
                    }
                </Box>
                <CustomerLocation
                    locationData={selectedBranch||customer}
                    addressTxt={addressTxt}
                    setAddressTxt={setAddressTxt}
                    setNewLocation={setNewLocation}
                />
                <Box textAlign={'center'} mb={2}>
                    <Button
                        variant='contained'
                        size='small'
                        onClick={updateLocation}
                    >Хадгалах</Button>
                </Box>
            </Dialog>
            {getToast(Boolean(msg?.m), msgOff, msg?.m, msg?.s)}
        </>
    )
}

